import emnrLogo from '@/assets/images/emnr_long.svg';
import useSize from '@/utils/useSize';
import React, { useEffect, useState } from 'react';
import 'react-dropdown/style.css';
import '@/utils/dropdown.css';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Menu from '../Menu';
import SearchContainer from './SearchContainer';

const NavbarTitle = styled.p`
  font-size: 1rem;
  color: white;
`;

const NavbarUnderContainer = styled.div`
  text-align: center;
  z-index: 2;
  position: relative;
  padding-bottom: 2rem;
`;

const Logo = styled.img`
  width: 15rem;
  @media (${({ theme }) => theme.device.tablet}) {
    width: 12rem;
  }
`;

const SmallLogo = styled.img`
  padding: 10px;
  cursor: pointer;
  width: 100px;
`;

const TopRow = styled.div`
  /* Add support for webkit */
  width: 100%;
  flex: 1;
  background-color: ${({ theme }) => theme.color.darkBlue};
  height: 60px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  flex-direction: row;
  vertical-align: center;
  justify-content: space-between;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 5;
`;

const TopRowFrontpage = styled.div`
  width: 100%;
  flex: 1;
  background-color: ${({ theme }) => theme.color.darkBlue};
  height: 60px;
  z-index: 10;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  flex-direction: row-reverse;
  vertical-align: center;
`;

const TopSearchpage = styled.div`
  /* Add support for webkit */
  width: 100%;
  background-color: ${({ theme }) => theme.color.darkBlue};
  height: 60px;
  align-items: center;
  display: flex;
  flex-direction: row;
  vertical-align: center;
  justify-content: space-between;
`;

const Curve = styled.div`
  position: relative;
  height: 70px;
  @media (${({ theme }) => theme.device.tablet}) {
    height: 50px;
  }
  &::after {
    border-radius: 100%;
    position: absolute;
    background: ${({ theme }) => theme.color.darkBlue};
    right: -200px;
    left: -200px;
    top: -200px;
    content: '';
    bottom: 0;
  }
`;

const HeaderBackground = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.color.darkBlue};
  width: 100%;
  height: 280px;
  top: 0;
  z-index: 1;
  @media (${({ theme }) => theme.device.tablet}) {
    height: 360px;
  }
`;

const HeaderOutline = styled.div`
  background-color: ${({ theme }) => theme.color.darkBlue};
  width: 100%;
  height: 350px;
  top: 0;
  z-index: 1;
  @media (${({ theme }) => theme.device.tablet}) {
    height: 400px;
  }
  background-color: #f1faee;
  position: relative;
  transition: all 0.5s ease;
  z-index: 6;
`;

const SearchPageContainer = styled.div`
  background-color: ${({ theme }) => theme.color.darkBlue};
  margin-bottom: 0.5em;
  z-index: 10;
  top: 0;
  position: sticky;
`;

const Navbar = () => {
  const navigate = useNavigate();
  const { isMobile } = useSize();
  const [inputValue, setInputValue] = useState('');
  const [sortOption, setSortOption] = useState('review_count');
  const [searchFieldChanged, setSearchFieldChanged] = useState(false);

  const courseCode: string = decodeURIComponent(useLocation().pathname.substring(8));

  const isOnFrontPage = useLocation().pathname === '/';
  const isOnSearchPage = useLocation().pathname === '/search';
  const isOnCoursePage = useLocation().pathname === `/course/${courseCode}`;

  const [searchParams] = useSearchParams();
  const sortOptionURL = searchParams.get('sortOption') ?? '';
  const inputValueURL = searchParams.get('inputValue') ?? '';

  useEffect(() => {
    if (isOnSearchPage && inputValue === '' && searchFieldChanged === false) {
      setInputValue(inputValueURL);
      setSearchFieldChanged(true);
    }
    if (isOnSearchPage && sortOption === 'review_count' && searchFieldChanged === false) {
      setSortOption(sortOptionURL);
    }

    if (isOnCoursePage && inputValue === '' && !searchFieldChanged) {
      setInputValue(inputValueURL);
    }
  }, [isOnSearchPage, inputValue, inputValueURL, sortOptionURL, sortOption, searchFieldChanged, isOnCoursePage]);

  if (isOnFrontPage) {
    return (
      <>
        <TopRowFrontpage>
          <Menu />
        </TopRowFrontpage>

        <HeaderOutline>
          <NavbarUnderContainer>
            <Logo height={75} src={emnrLogo} onClick={() => navigate('/')} alt='Logo of the emnr.no website' />
            <NavbarTitle>- Av studenter for studenter -</NavbarTitle>
            <SearchContainer
              inputValue={inputValue}
              setInputValue={setInputValue}
              sortOption={sortOption}
              setSortOption={setSortOption}
              setSearchFieldChanged={setSearchFieldChanged}
              searchFieldChanged={searchFieldChanged}
            />
          </NavbarUnderContainer>
          <HeaderBackground>
            <div style={{ height: '100%' }} />
            <div style={{ position: 'relative', overflow: 'hidden' }}>
              <Curve />
            </div>
          </HeaderBackground>
        </HeaderOutline>
      </>
    );
  }
  if ((isOnCoursePage || isOnSearchPage) && !isMobile) {
    return (
      <SearchPageContainer>
        <TopSearchpage>
          <SmallLogo src={emnrLogo} onClick={() => navigate('/')} alt='Logo of the emnr.no website' />
          <SearchContainer
            inputValue={inputValue}
            setInputValue={setInputValue}
            sortOption={sortOption}
            setSortOption={setSortOption}
            searchFieldChanged={searchFieldChanged}
            setSearchFieldChanged={setSearchFieldChanged}
          />
          <Menu />
        </TopSearchpage>
      </SearchPageContainer>
    );
  }
  if ((isOnCoursePage || isOnSearchPage) && isMobile) {
    return (
      <SearchPageContainer>
        <TopSearchpage>
          <SmallLogo src={emnrLogo} onClick={() => navigate('/')} alt='Logo of the emnr.no website' />
          <Menu />
        </TopSearchpage>
        <SearchContainer
          inputValue={inputValue}
          setInputValue={setInputValue}
          sortOption={sortOption}
          setSortOption={setSortOption}
          searchFieldChanged={searchFieldChanged}
          setSearchFieldChanged={setSearchFieldChanged}
        />
      </SearchPageContainer>
    );
  }

  return (
    <TopRow>
      <SmallLogo src={emnrLogo} onClick={() => navigate('/')} alt='Logo of the emnr.no website' />
      <Menu />
    </TopRow>
  );
};

export default Navbar;
