import React from 'react';
import styled from 'styled-components';

interface ButtonProps {
  clickHandler?: () => void;
  children: React.ReactNode;
}

const StyledLightLoginBtn = styled.button`
  color: ${({ theme }) => theme.color.light};
  background-color: ${({ theme }) => theme.color.blue};
  border: none;
  border-radius: 5px;
  font-family: poppinsregular;
  padding: 10px 10px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  :hover {
    background-color: ${({ theme }) => theme.color.lightlightBlue};
    color: ${({ theme }) => theme.color.darkBlue};
  }
`;

const StyledDarkLoginBtn = styled(StyledLightLoginBtn)`
  color: ${({ theme }) => theme.color.light};
  background-color: ${({ theme }) => theme.color.darkBlue};
  padding: 15px 10px;
  :hover {
    background-color: ${({ theme }) => theme.color.blue};
    color: ${({ theme }) => theme.color.light};
  }
`;

export const NavbarButton = styled.button`
  color: ${({ theme }) => theme.color.light};
  background-color: ${({ theme }) => theme.color.blue};
  border: none;
  width: 75px;
  @media (${({ theme }) => theme.device.laptopL}) {
    width: 50px;
  }
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  @media (${({ theme }) => theme.device.tablet}) {
    border-radius: 15px;
  }
  padding: 8px 16px;
  font-family: poppinsregular;
  cursor: pointer;
  user-select: none;
  outline: none;
  flex: 1;
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.color.lightBlue};
      color: ${({ theme }) => theme.color.darkBlue};
    }
  }
`;

export const NavbarRemoveButton = styled(NavbarButton)`
  background-color: ${({ theme }) => theme.color.red};
  margin-left: 12px;
`;

export const SortHighLowButton = styled.button`
  color: ${({ theme }) => theme.color.black};
  background-color: ${({ theme }) => theme.color.lightBlue};
  border: none;
  border-radius: 3px;
  font-family: poppinslight;
  margin: 0;
  padding: 4px 4px;
  cursor: pointer;
  user-select: none;
  outline: none;
  :disabled {
    background-color: #bbbbbb;
    cursor: auto;
    color: #333333;
  }
  :enabled {
    :hover {
      background-color: ${({ theme }) => theme.color.lightlightBlue};
      color: ${({ theme }) => theme.color.darkBlue};
    }
  }
`;

export const SortButton = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.color.darkBlue};
  color: ${({ theme }) => theme.color.white};
  cursor: pointer;
  flex: 1;
  font-family: poppinsregular;
  font-size: 1.2em;
  padding: 10px 30px;
  border-radius: 10px;
  margin: 0;
  :hover {
    background-color: ${({ theme }) => theme.color.blue};
  }
`;

export const ResetButton = styled.button`
  color: ${({ theme }) => theme.color.blue};
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  font-family: poppinsregular;
  font-size: 1rem;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.color.darkBlue};
  }
`;

export const Checkbox = styled.input`
  margin-top: 0;
  appearance: none;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.black};
  width: 20px;
  height: 20px;
  text-align: center;
  display: inline-block;

  &:focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.color.darkBlue};
  }

  &:checked::after {
    content: '\u2714';
    font-size: 12px;
    color: ${({ theme }) => theme.color.black};
  }
`;

export const LoginButtonLight: React.FC<ButtonProps> = ({ clickHandler, children }: ButtonProps) => {
  return <StyledLightLoginBtn onClick={clickHandler}>{children}</StyledLightLoginBtn>;
};

export const LoginButtonDark: React.FC<ButtonProps> = ({ clickHandler, children }: ButtonProps) => {
  return <StyledDarkLoginBtn onClick={clickHandler}>{children}</StyledDarkLoginBtn>;
};

export const ModalXButton = styled.span`
  font-size: 1.5em;
  margin-top: -5px;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.color.red};
  }
`;

export const RedButton = styled.button`
  background-color: ${({ theme, disabled }) => (disabled ? '#aaa' : theme.color.red)};
  padding: 10px 20px;
  color: ${({ theme, disabled }) => (disabled ? null : theme.color.white)};
  border: ${({ disabled }) => (disabled ? '1px solid #aaa' : '1px solid black')};
  border: 1px solid black;
  width: fit-content;
  font-family: poppinsmedium;
  font-size: 16px;
  border-radius: 200px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  @media ${({ theme }) => theme.device.mobileL} {
    font-size: 14px;
  }
`;

export const TooltipButtonContainer = styled.div`
  position: relative;
  display: inline-flex;
`;

export const CarouselButton = styled.button`
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  margin-right: 0.4rem;
  margin-top: 1.75rem;
  border: 1px;
  cursor: pointer;
  outline: none;
  padding: 0;
`;

export const IconButton = styled.button`
  background-color: Transparent;
  height: 100%;
  padding: 0;
  margin-top: 4rem;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
`;

export const BlueButton = styled(RedButton)`
  background-color: ${({ theme, disabled }) => (disabled ? '#aaa' : theme.color.blue)};
  color: ${({ theme }) => theme.color.white};
  border: ${({ disabled }) => disabled && '1px solid #aaa'};
  padding: 10px 25px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;
