import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import API_URL from '@/config';
import { PulseLoader } from 'react-spinners';
import { BlueButton, TooltipButtonContainer } from '@/styles/Buttons';
import { useQuery } from '@tanstack/react-query';
import { getLocalTokenUnverified } from '../utils/auth';

interface RateCourseButtonProps {
  onClickFunction: () => void;
  courseCode: string;
  edit: boolean;
}

export const TooltipText = styled.div`
  font-size: 0.7em;
  position: absolute;
  top: 100%;
  margin: 4px 0 0 0;
  padding: 8px;
  background-color: #444;
  color: ${({ theme }) => theme.color.white};
  width: max-content;
  visibility: hidden;
  opacity: 0;
  transition-duration: 0.5s;

  ${TooltipButtonContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

const useReviewEligibilityQuery = (courseCode: string) =>
  useQuery(['review', courseCode, 'reviewable', getLocalTokenUnverified()], async () => {
    const response = await axios.get(`${API_URL}/review/check/?courseCode=${courseCode}`);
    return response.data;
  });

export const RateCourseButton: React.FC<RateCourseButtonProps> = ({ onClickFunction, courseCode, edit }) => {
  const { data, isError, isLoading } = useReviewEligibilityQuery(courseCode);
  if (!getLocalTokenUnverified()) {
    return (
      <TooltipButtonContainer>
        <BlueButton disabled>Logg inn for å vurdere {courseCode}</BlueButton>
        <TooltipText>Du må være innlogget for å vurdere emnet</TooltipText>
      </TooltipButtonContainer>
    );
  }
  if (isLoading) {
    return (
      <BlueButton disabled>
        <PulseLoader color='#FFF' size='10px' />
      </BlueButton>
    );
  }
  if (isError) {
    return <div>Noe gikk galt mens vi sjekket om du kan vurdere dette emnet</div>;
  }
  if (edit) {
    return <BlueButton onClick={() => onClickFunction()}> Lagre endringer </BlueButton>;
  }
  if (typeof data === 'string') {
    return (
      <TooltipButtonContainer>
        <BlueButton disabled>Vurder {courseCode}</BlueButton>
        <TooltipText>{data}</TooltipText>
      </TooltipButtonContainer>
    );
  }
  return <BlueButton onClick={() => onClickFunction()}>Vurder {courseCode}</BlueButton>;
};
